"use strict";

/*!
	Author: Jakub Kordovský
	Version: 2.0
*/

class InfinityCarousel {
	constructor(carousel, options = {}) {
		if (!carousel) return;

		const defaults = {
			autorotate: true,
			interval: 6000,
			navContainer: null,
			indicatorContainer: null,
			transitionDuration: 250,
			transitionEasing: "ease-out",
			buttonLeftHTML: null,
			buttonRightHTML: null,
			showAllIndicators: false,
			clickableIndicators: true,
			allowTouchControls: true,
		};

		this.options = { ...defaults, ...options };
		this.carousel = carousel;
		this.initialiaze();
	}

	pauseOnMouse(element) {
		element.addEventListener("mouseenter", () => {
			clearInterval(this.rotate);
		});

		element.addEventListener("mouseleave", () => {
			if (this.options.autorotate && !this.isStopped) {
				this.rotate = setInterval(() => {
					this.next();
				}, this.options.interval);
			}
		});
	}

	initialiaze() {
		if (this.initialiazed) return;

		this.carousel.id = `infinity-${Date.now()}`;
		this.slides = Array.from(this.carousel.children);
		this.amount = this.slides.length;

		if (this.amount < 2) return;

		this.itemClass = Array.from(this.slides[0].classList).join(".");

		if (this.itemClass.length === 0) {
			this.itemClass = this.slides[0].nodeName.toLowerCase();
		} else {
			this.itemClass = `.${this.itemClass}`;
		}

		this.currentIndex = 0;
		this.realIndex = 0;
		this.indicators = [];
		this.isStopped = true;

		this.carousel.scrollTo(0, 0);
		this.currentTransform = 0;
		//this.doTransform(this.amount);
		this.lastIndex = this.amount - 1;

		this.carousel.parentNode.dataset.infinityInitialised = "";

		let clones = [];

		this.slides.forEach((slide, index) => {
			slide.dataset.index = index;
			const clone = slide.cloneNode(true);
			clone.setAttribute("aria-hidden", "true");
			clones.push(slide.cloneNode(true));

			this.carousel.insertAdjacentElement("beforeend", clone);
		});

		clones = clones.reverse();

		clones.forEach((clone) => {
			clone.setAttribute("aria-hidden", "true");
			this.carousel.insertAdjacentElement("afterbegin", clone);
		});

		if (this.options.navContainer) {
			this.createNavigation();
		}

		if (this.options.indicatorContainer) {
			this.createIndicators();
		}

		this.carousel.addEventListener("transitionend", () => {
			if (
				this.options.indicatorContainer &&
				this.options.showAllIndicators
			) {
				const visible = this.findVisible(true);

				this.indicators.forEach((indicator, index) => {
					indicator.classList.toggle(
						"infinity-indicator--current",
						visible.includes(index)
					);
				});
			}

			if (this.realIndex === this.amount || this.realIndex === -1) {
				this.removeTransition();

				if (this.realIndex === this.amount) {
					this.doTransform(-this.amount);
					this.realIndex = 0;
					this.currentIndex = 0;
				}

				if (this.realIndex === -1) {
					this.doTransform(-(this.lastIndex + this.amount));
					this.realIndex = this.lastIndex;
					this.currentIndex = this.lastIndex;
				}

				this.resetRotation();
				return;
			}
		});

		if (this.options.autorotate) {
			this.isStopped = false;
			this.rotate = setInterval(() => {
				this.next();
			}, this.options.interval);

			this.pauseOnMouse(this.carousel);
		}

		if (this.options.allowTouchControls) {
			this.addTouchListeners();
		}

		this.slideTo(this.currentIndex);

		document.onreadystatechange = () => {
			if (document.readyState === "complete") {
				const visible = this.findVisible(true);

				this.indicators.forEach((indicator, index) => {
					indicator.classList.toggle(
						"infinity-indicator--current",
						visible.includes(index)
					);
				});
			}
		};

		window.addEventListener("resize", () => {
			this.slideTo(this.currentIndex);
		});

		this.initialiazed = true;
	}

	addTouchListeners() {
		let touchStartFingerPositionX = null;
		let touchStartFingerPositionY = null;
		let touchSnapThreshold = null;

		let touchStartListener = ({ touches }) => {
			clearInterval(this.rotate);

			if (touches.length > 1) {
				return;
			}

			touchSnapThreshold = Math.min(
				this.carousel.parentNode.offsetWidth / 4,
				120
			);
			touchStartFingerPositionX = touches[0].screenX;
			touchStartFingerPositionY = touches[0].screenY;
		};

		let touchMoveListener = (event) => {
			const touchDifference =
				touchStartFingerPositionX - event.changedTouches[0].screenX;
			const shift = Math.abs(touchDifference);

			if (
				Math.abs(
					touchStartFingerPositionY - event.changedTouches[0].screenY
				) > shift
			)
				return;

			if (event.cancelable) event.preventDefault();

			//const operator = touchDifference * -1 >= 0 ? "+" : "-";
			this.removeTransition();
			const amount =
				touchDifference * -1 >= 0
					? this.currentTransform + shift
					: this.currentTransform - shift;
			this.carousel.style.transform = `translate3d(${amount}px, 0, 0)`;
		};

		let touchEndListener = (event) => {
			const touchDifference =
				touchStartFingerPositionX - event.changedTouches[0].screenX;
			touchStartFingerPositionY - event.changedTouches[0].screenY;

			if (Math.abs(touchDifference) > touchSnapThreshold) {
				event.preventDefault();

				if (touchDifference > 0) {
					this.next();
				} else {
					this.previous();
				}
			} else {
				this.slideTo(this.currentIndex);
			}

			touchStartFingerPositionX = null;
			touchStartFingerPositionY = null;
			touchSnapThreshold = null;
		};

		this.carousel.addEventListener(
			"touchstart",
			touchStartListener.bind(this)
		);
		this.carousel.addEventListener("touchmove", touchMoveListener.bind(this));
		this.carousel.addEventListener("touchend", touchEndListener.bind(this));
	}

	findVisible(indexes = false) {
		const wrapperRect = this.carousel.parentNode.getBoundingClientRect();
		const visible = [];

		Array.from(this.carousel.children).forEach((child) => {
			const rect = child.getBoundingClientRect();

			if (
				rect.top + rect.height > wrapperRect.top &&
				rect.left + rect.width > wrapperRect.left &&
				rect.bottom - rect.height < wrapperRect.bottom &&
				rect.right - rect.width < wrapperRect.right
			) {
				visible.push(indexes ? parseInt(child.dataset.index) : child);
			}
		});

		return visible;
	}

	resetRotation() {
		if (this.options.autorotate && !this.isStopped) {
			clearInterval(this.rotate);

			this.rotate = setInterval(() => {
				this.next();
			}, this.options.interval);
		}
	}

	stopRotation() {
		if (!this.isStopped) {
			clearInterval(this.rotate);
			this.isStopped = true;
		}
	}

	createNavigation() {
		const buttonLeft = document.createElement("button");
		const buttonRight = document.createElement("button");

		buttonLeft.className = "infinity-button infinity-button--previous";
		buttonLeft.type = "button";
		buttonLeft.setAttribute("aria-controls", this.carousel.id);
		buttonRight.className = "infinity-button infinity-button--next";
		buttonRight.type = "button";
		buttonRight.setAttribute("aria-controls", this.carousel.id);
		buttonLeft.innerHTML = this.options.buttonLeftHTML;
		buttonRight.innerHTML = this.options.buttonRightHTML;

		buttonLeft.addEventListener("click", () => {
			this.stopRotation();
			this.previous();
		});
		buttonRight.addEventListener("click", () => {
			this.stopRotation();
			this.next();
		});

		this.options.navContainer.insertAdjacentElement("afterbegin", buttonLeft);
		this.options.navContainer.insertAdjacentElement("beforeend", buttonRight);
	}

	createIndicators() {
		this.slides.forEach(({ index }) => {
			const indicator = document.createElement("span");
			indicator.className = "infinity-indicator";
			this.indicators.push(indicator);
			this.options.indicatorContainer.appendChild(indicator);

			if (this.options.clickableIndicators) {
				indicator.setAttribute("aria-label", `${index + 1}. položka`);
				indicator.setAttribute("aria-controls", this.carousel.id);
				indicator.setAttribute("role", "button");

				indicator.addEventListener("click", () => {
					this.stopRotation();
					this.slideTo(index);
				});
			} else {
				this.options.indicatorContainer.setAttribute("aria-hidden", "true");
			}
		});
	}

	next() {
		this.slideTo(this.currentIndex + 1);
	}

	previous() {
		this.slideTo(this.currentIndex - 1);
	}

	doTransform(index) {
		const amount = this.slides[0].offsetWidth * index;
		this.carousel.style.transform = `translate3d(${amount}px, 0, 0)`;
		this.currentTransform = amount;
	}

	removeTransition() {
		this.carousel.style.transition = "none";
	}

	setTransition() {
		this.carousel.style.transition = `transform ${this.options.transitionDuration}ms ${this.options.transitionEasing}`;
	}

	slideTo(index) {
		this.realIndex = index;

		if (index === -1 || index === this.amount) {
			const nextIndicatorIndex = index === -1 ? this.lastIndex : 0;
			this.setTransition();

			if (
				this.options.indicatorContainer &&
				!this.options.showAllIndicators
			) {
				this.indicators[this.currentIndex].classList.remove(
					"infinity-indicator--current"
				);
				this.indicators[nextIndicatorIndex].classList.add(
					"infinity-indicator--current"
				);
			}

			this.doTransform(-(this.amount + index));
			return;
		}

		if (index >= 0 && index < this.amount) {
			if (
				this.options.indicatorContainer &&
				!this.options.showAllIndicators
			) {
				this.indicators[this.currentIndex].classList.remove(
					"infinity-indicator--current"
				);
			}

			this.currentIndex = index;

			if (
				this.options.indicatorContainer &&
				!this.options.showAllIndicators
			) {
				this.indicators[this.currentIndex].classList.add(
					"infinity-indicator--current"
				);
			}

			this.setTransition();
			this.doTransform(-(this.amount + this.currentIndex));
			this.resetRotation();
		}
	}
}
